import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.career');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-admin-career',
template: templateSource || '',
    styles: [`

/* file: /opt/seasoncokr-v2/branch/main/build/src/app/page.admin.career/view.scss */
.card-header h2 {
  font: normal normal bold 30px Montserrat;
}

span {
  font: normal normal normal 16px SUIT;
}

table {
  text-align: left;
}

.form-group {
  padding: 15px 0px;
  border-top: 1px solid #E3E3E3;
}
.form-group .form-label {
  display: flex;
  align-items: center;
  font: normal normal bold 14px/18px SUIT;
  margin-bottom: 0px;
}
.form-group .form-selectgroup-label {
  padding: 7px 16px;
}

.form-selectgroup-input:checked + .form-selectgroup-label {
  z-index: 1;
  color: #FE5A33;
  background: rgba(254, 90, 51, 0.04);
  border-color: #FE5A33;
}

.edit-form {
  padding: 24px 32px;
}

.page-icon.back-btn {
  cursor: pointer;
  background-color: #FE5A33 !important;
}

input,
textarea {
  text-align: left !important;
  border-radius: 0 !important;
}`],
})
export class PageAdminCareerComponent implements OnInit {
    public post = [];
    public list = {};
    public mode = 0;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/authenticate');
        await this.load();
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async load() {
        const { code, data } = await wiz.call("load");
        if (code !== 200) {
            let error = "[ERROR] load career";
            await this.alert(error);
            return;
        }

        this.post = data;
        await this.service.render();
    }

    public async tab(data) {
        this.mode = data;

        if (data === 0) {
            this.list = {};
        }

        await this.service.render();
    }

    public async create() {
        this.list = {};
        await this.tab(1);
    }

    public async edit(index) {
        this.list = this.post[index];
        await this.tab(1);
    }

    public async save() {
        let res = await this.service.alert.show({
            title: "",
            message: "저장하시겠습니까?",
            action: "Yes",
            cancel: "No"
        });
        if (!res)
            return;

        let message = "";
        let status = "error";

        if (!this.list.team) {
            message = "채용 공고를 진행할 팀을 정해주세요";
        }
        else if (!this.list.title) {
            message = "채용 공고를 진행할 제목을 작성해주세요";
        }
        else if (!this.list.content) {
            message = "채용 공고와 관련한 내용을 작성해주세요";
        }
        else if (!this.list.mode) {
            message = "채용 공고의 채용 방식을 정해주세요";
        }
        else {
            message = "정보가 성공적으로 저장되었습니다.";
            status = "success";

            const { code } = await wiz.call("save", this.list);
            if (code !== 200) {
                let error = "오류로 인해 데이터를 저장할 수 없습니다.";
                await this.alert(error);
                return;
            }

            await this.tab(0);
            await this.load();
        }

        await this.alert(message, status);
    }

    public async remove() {
        let res = await this.service.alert.show({
            title: "",
            message: "정말로 삭제하시겠습니까?",
            action: "Yes",
            cancel: "No"
        });
        if (!res)
            return;

        const { code } = await wiz.call("remove", { id: this.list.id });
        if (code !== 200) {
            let error = "오류로 인해 데이터를 삭제할 수 없습니다.";
            await this.alert(error);
            return;
        }

        await this.alert("선택한 항목이 성공적으로 삭제되었습니다.", "success");
        await this.tab(0);
        await this.load();
    }
}

export default PageAdminCareerComponent;