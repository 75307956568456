import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.news.item');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import showdown from 'showdown';
import { Location } from '@angular/common'

@Component({
    selector: 'wiz-page-news-item',
template: templateSource || '',
    styles: [`

/* file: /opt/seasoncokr-v2/branch/main/build/src/app/page.news.item/view.scss */
.header {
  background-image: linear-gradient(108deg, rgba(0, 0, 0, 0.5), transparent), url("/assets/featured/news.jpg");
}
.header .xmark {
  position: absolute;
  top: 48px;
  right: 48px;
  cursor: pointer;
}
.header .xmark i {
  color: #fff;
  font-size: 32px;
}
.header .xmark:hover i {
  color: #FF5933;
}
@media (max-width: 768px) {
  .header .xmark {
    top: 32px;
    right: 32px;
  }
}

.content {
  padding-top: 102px;
  padding-bottom: 176px;
  min-height: 640px;
}

.content-container {
  padding: 64px auto;
  font-size: 18px;
}
.content-container .content-body {
  max-width: 860px;
  margin: 0 auto;
}`],
})
export class PageNewsItemComponent implements OnInit {
    constructor(@Inject( Service) public service: Service,@Inject( Location)  public location: Location) { }

    public category: any = 'notice';
    public post_id: any;
    public item: any;

    public async ngOnInit() {
        await this.service.init();
        this.category = WizRoute.segment.category;
        this.post_id = WizRoute.segment.id;
        await this.load();
    }

    public async load() {
        const { code, data } = await wiz.call("get", { id: this.post_id });
        this.item = data;
        await this.service.render();
        await this.service.trigger.call("scroll-top");
    }

    public desc() {
        let item: any = this.item;
        let text: any = item.content[this.service.lang.get()];
        let converter = new showdown.Converter();
        text = converter.makeHtml(text);
        return text;
    }

    public back() {
        this.location.back();
    }
}

export default PageNewsItemComponent;