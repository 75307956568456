import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.footer');
import { OnInit } from '@angular/core';

@Component({
    selector: 'wiz-component-footer',
template: templateSource || '',
    styles: [`

/* file: /opt/seasoncokr-v2/branch/main/build/src/app/component.footer/view.scss */
.content {
  position: relative;
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  height: auto;
  padding: 0;
  margin-top: -60px;
}
@media (max-width: 768px) {
  .content {
    display: none;
  }
}
.content h2 {
  font: normal normal bold 60px Montserrat;
  margin-bottom: 30px;
}
.content .content-container {
  width: 100%;
  max-width: 1024px;
  margin-top: 120px;
  margin-bottom: 80px;
}
.content h1 {
  font: normal normal bold 48px/52px Montserrat;
}
.content .info {
  margin-top: 100px;
  display: flex;
}
.content .info .col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.content .info .col-auto {
  display: flex;
  flex-direction: column;
}
.content .info span {
  font: normal normal normal 15px/24px SUIT;
}

.btn-season {
  color: white;
  background-color: #FE5A33;
  border: 0;
  border-radius: 30px;
  padding: 15px 20px;
}
.btn-season > span {
  padding: 0px 15px;
  font: normal normal normal 20px/25px Montserrat;
  width: 145px;
}
.btn-season .btn-circle {
  margin-left: 10px;
  width: 30px;
  height: 30px;
  border-radius: 999px;
  background-color: white;
  color: #FE5A33;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-season .btn-circle i {
  font-size: 20px;
}

.btn-season:hover > span {
  color: white;
  font-weight: bold;
}

.btn-season:hover .btn-circle {
  background-color: white;
  border: 2px solid white;
}

.btn:active {
  color: white;
  background-color: #FE5A33;
}`],
})
export class ComponentFooterComponent implements OnInit {
    public async ngOnInit() {
    }
}

export default ComponentFooterComponent;