import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.main');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';
import showdown from 'showdown';

@Component({
    selector: 'wiz-page-main',
template: templateSource || '',
    styles: [`

/* file: /opt/seasoncokr-v2/branch/main/build/src/app/page.main/view.scss */
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(108deg, rgba(0, 0, 0, 0.5), transparent), url("/assets/featured/intro.jpg");
}
@media (max-width: 768px) {
  .header {
    min-height: 260px;
  }
}
.header .text-container {
  margin-bottom: 8px;
  text-align: center;
  color: #fff;
}
.header .text-container.goal-typing {
  display: block;
}
.header .text-container.goal-text {
  display: none;
}
.header .text-container.goal-text h2 {
  font-size: 32px;
  line-height: 1.1;
  margin: 0;
  padding: 0;
}
@media (max-width: 768px) {
  .header .text-container.goal-typing {
    display: none;
  }
  .header .text-container.goal-text {
    display: block;
  }
}

.bold {
  font-weight: bold !important;
}

.content-mission {
  background-color: #000;
  color: #fff;
}
.content-mission .mission {
  display: flex;
  padding: 50px 0px;
  padding-top: 28px;
  max-width: 1180px;
  margin: auto;
}
.content-mission .mission .mission-featured {
  font-family: SUIT;
  font-size: 24px;
  font-weight: 500;
  max-width: 480px;
  flex: 0 0 auto;
  width: auto;
}
@media (max-width: 768px) {
  .content-mission .mission .mission-featured {
    font-size: 20px;
  }
}
.content-mission .mission .mission-text {
  max-width: 100%;
  margin-left: 80px;
  font-family: SUIT;
  font-size: 16px;
  color: #fff;
}
@media (max-width: 768px) {
  .content-mission .mission .mission-text {
    margin-left: 32px;
    margin-top: 12px;
  }
}
@media (max-width: 768px) {
  .content-mission .mission {
    flex-direction: column;
    padding-top: 16px;
  }
  .content-mission .mission .col {
    margin-left: 0px;
    margin-top: 30px;
  }
}
.content-mission .mission-img {
  width: 100%;
  padding-bottom: 32.5%;
  position: relative;
  max-width: 1180px;
  margin: auto;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url("/assets/page/main/mission.png");
}

.content-solutions .description {
  margin-bottom: 28px;
  font-size: 18px;
  color: #667382;
}
.content-solutions .solution {
  border-radius: 30px;
  background-color: #E9E8EE;
  padding: 32px 36px;
  margin: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  cursor: pointer;
}
.content-solutions .solution h3 {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 25px;
}
.content-solutions .solution .solution-text {
  font: normal normal normal 18px/30px SUIT;
}
.content-solutions .solution .card-img {
  width: 100%;
  padding-bottom: 50%;
  position: relative;
  margin-bottom: 24px;
  border-radius: 30px;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  background-position: 50% 50%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.content-solutions .solution .dizest {
  background-image: url("/assets/page/main/dizest.jpg");
}
.content-solutions .solution .wiz {
  background-image: url("/assets/page/main/wiz.jpg");
}
.content-solutions .solution .works {
  background-image: url("/assets/page/main/works.jpg");
}
.content-solutions .solution:hover {
  color: white;
  background-color: #FE5A33;
}
.content-solutions .solution:hover .card-img {
  background-size: 110%;
}
@media (max-width: 768px) {
  .content-solutions .row {
    flex-direction: column;
  }
  .content-solutions .solution {
    margin: 0;
    margin-bottom: 24px;
  }
}

.content-partner {
  background-color: #2b2c2e;
  color: #fff;
}
.content-partner .partner-card {
  margin: 12px 6px;
  height: 120px;
  background-color: #F5F5F5;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-partner .partner-card img {
  width: 60%;
}

.content-location .location .maps {
  width: 100%;
  height: 420px;
  border-radius: 20px;
  border: 1px solid #494A53;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .content-location .location .maps {
    height: 300px;
  }
}
.content-location .location h3 {
  font: normal normal bold 20px Montserrat;
}
.content-location .location span,
.content-location .location td {
  font: normal normal 500 18px Montserrat;
  color: #494A52;
  padding-bottom: 20px;
}

.wrap img {
  margin: 0px 0 0 -25px;
  height: 50px;
  line-height: 60px;
  position: fixed;
  left: 50%;
  bottom: 0px;
  z-index: 999;
}
@media (max-width: 768px) {
  .wrap img {
    display: none;
  }
}

.bounce {
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
  animation: bounce 3.6s ease infinite;
  transform-origin: 50% 50%;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  5.55556% {
    transform: translateY(0);
  }
  11.11111% {
    transform: translateY(0);
  }
  22.22222% {
    transform: translateY(-15px);
  }
  27.77778% {
    transform: translateY(0);
  }
  33.33333% {
    transform: translateY(-15px);
  }
  44.44444% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}
.input-group {
  outline: none !important;
  box-shadow: none !important;
}

.chat-item .row {
  flex-direction: row;
}
.chat-item h1,
.chat-item h2,
.chat-item h3,
.chat-item h4 {
  font-size: 18px !important;
  padding-bottom: 0 !important;
}`],
})
export class PageMainComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @ViewChild('maps')
    public mapsElement: ElementRef;

    public logo: any = [];

    public async ngOnInit() {
        await this.service.init();
        await this.service.render();

        let res = await wiz.call("partner");
        this.logo = res.data.ko.data;

        await this.service.trigger.call("scroll-top");

        let markerPosition = new kakao.maps.LatLng(36.48409755878336, 127.25900153533746);
        let marker = { position: markerPosition };
        let staticMapContainer = this.mapsElement.nativeElement,
            staticMapOption = {
                center: new kakao.maps.LatLng(36.48409755878336, 127.25900153533746),
                level: 3,
                marker: marker
            };
        new kakao.maps.StaticMap(staticMapContainer, staticMapOption);
    }

    public async location() {
        window.open("https://goo.gl/maps/FLDWVrWR15ozbwdX9");
    }

    public q: string = '';
    public qaset: any = [];
    public qstat: boolean = true;

    public async query() {
        if (this.q.length <= 0) return;
        let q = this.q + "";
        this.qstat = false;
        this.q = "";

        let qset: any = { q: q, a: "" };
        this.qaset.push(qset);
        await this.service.render();

        const { code, data } = await wiz.call("query", { q: q });
        qset.a = data;
        this.qstat = true;
        await this.service.render();
    }

    public showdown(text) {
        let converter = new showdown.Converter();
        return converter.makeHtml(text);
    }
}

export default PageMainComponent;