import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('layout.season');
import { OnInit, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import $ from 'jquery';

@Component({
    selector: 'wiz-layout-season',
template: templateSource || '',
    styles: [`

/* file: /opt/seasoncokr-v2/branch/main/build/src/app/layout.season/view.scss */
body {
  width: 100%;
}

.wiz-page {
  width: 100%;
  margin: 0;
  z-index: 2;
  flex-direction: column;
}

.wiz-nav {
  max-width: 1448px;
  width: 90vw;
  margin: 0px auto;
}
@media (max-width: 768px) {
  .wiz-nav {
    width: 92vw;
  }
}

.wiz-view {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.wiz-view::-webkit-scrollbar {
  display: none;
}

.season-page {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  max-width: 1440px;
  width: 90vw;
  margin: 0px auto;
  border-top: 1px solid #2b2c2e;
  border-left: 1px solid #2b2c2e;
  border-right: 1px solid #2b2c2e;
}
@media (max-width: 768px) {
  .season-page {
    width: 92vw;
  }
}

.season-page::-webkit-scrollbar {
  display: none;
}

.scroll-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 10px;
  background-color: transparent;
  overflow: auto;
}
.scroll-wrap .scrollbar {
  width: 1px;
}`],
})
export class LayoutSeasonComponent implements OnInit, DoCheck {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        this.service.trigger.bind('scroll-top', async () => {
            $('.season-page')[0].scroll({ top: 0, left: 0, behavior: 'smooth' });
        });

        const content = document.querySelector(".season-page");
        document.querySelector(".wiz-view").addEventListener("wheel", e => {
            e.stopPropagation();
            if (document.querySelector(".wiz-modal .modal-content")) return;
            this.setScroll();
            try {
                content.scrollTop += e.deltaY;
                const scrollwrap = document.querySelector(".wiz-page > .wiz-view > .scroll-wrap");
                scrollwrap.scrollTop = content.scrollTop;
            } catch { }
        });

        const scrollEl = document.querySelector(".scroll-wrap");
        if (!scrollEl) return;
        scrollEl.addEventListener("scroll", e => {
            const { scrollTop } = e.target;
            if (content.scrollTop === scrollTop) return;
            content.scrollTop = scrollTop;
        });
    }

    public ngDoCheck() {
        this.setScroll();
    }

    private setScroll() {
        try {
            const height = document.querySelector(".wiz-page > .wiz-view > .season-page").scrollHeight;
            const el = document.querySelector(".wiz-page > .wiz-view > .scroll-wrap > .scrollbar");
            el.style.height = `${height}px`;
        } catch { }
    }
}

export default LayoutSeasonComponent;