import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.about');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-about',
template: templateSource || '',
    styles: [`

/* file: /opt/seasoncokr-v2/branch/main/build/src/app/page.about/view.scss */
.header {
  background-image: linear-gradient(108deg, rgba(0, 0, 0, 0.5), transparent), url("/assets/featured/about.jpg");
}

.content-history {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("/assets/featured/history.jpg");
  background-size: cover;
}
.content-history .history {
  padding-top: 50px;
  max-width: 1180px;
  margin: auto;
}
.content-history .history .row {
  margin-bottom: 48px;
}
.content-history .history .year {
  width: 180px;
  font-weight: bold;
  font-family: Montserrat;
  margin: 0;
  padding: 0;
  font-size: 48px;
}
.content-history .history .month {
  width: 160px;
  color: #9799A6;
  font-size: 24px;
  font-weight: bold;
  font-family: Montserrat;
  margin: 0;
  padding: 0;
}
.content-history .history .title {
  color: #2B2C2E;
  font-weight: normal;
  font-size: 20px;
  font-family: Montserrat;
}
.content-history .history .list {
  display: flex;
  align-items: center;
  padding: 8px 0px;
  border-bottom: 1px solid #9799A6;
}
@media (max-width: 768px) {
  .content-history .history .row {
    display: block;
  }
  .content-history .history .row .month {
    width: 80px;
    font-size: 20px;
  }
  .content-history .history .row .title {
    font-size: 18px;
  }
}

.team {
  max-width: 1180px;
  margin: auto;
  margin-bottom: 64px;
}
.team .title {
  font: normal normal bold 24px/30px SUIT;
  margin-right: 24px;
}
.team .detail {
  font: normal normal normal 16px/20px SUIT;
  color: #72787F;
}
.team .row {
  margin-top: 18px;
  border-top: 1px solid #C9CDD2;
}
.team .row .profile {
  margin-top: 24px;
  margin-left: 0px;
  width: 212px;
  margin: 24px 30px 0px 0px;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.team .row .profile .image {
  width: 212px;
  height: 212px;
  border-radius: 10px;
}
.team .row .profile .name {
  margin-top: 24px;
  margin-left: 0px;
  font: normal normal bold 24px/30px SUIT;
}
.team .row .profile .position {
  margin-left: 0px;
  font: normal normal normal 16px/20px SUIT;
}
.team .row .profile .email {
  margin-left: 0px;
  font: normal normal normal 16px/20px SUIT;
}`],
})
export class PageAboutComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public data: any = {};

    public async ngOnInit() {
        await this.service.init();
        await this.service.render();
        await this.load();
        await this.service.trigger.call("scroll-top");
    }

    public async load() {
        let res = await wiz.call("history");
        this.data.history = res.data;
        res = await wiz.call("team");
        this.data.team = res.data;
        await this.service.render();
    }

    public history() {
        try {
            let lang: any = this.service.lang.get();
            let data: any = this.data.history;
            let res: any = data.ko.data;
            if (data[lang]) res = data[lang].data;
            return res;
        } catch (e) {
        }
        return [];
    }

    public team() {
        try {
            let lang: any = this.service.lang.get();
            let data: any = this.data.team;
            let res: any = data.ko.data;
            if (data[lang]) res = data[lang].data;
            return res;
        } catch (e) {
        }
        return [];
    }
}

export default PageAboutComponent;