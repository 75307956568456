import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.web.solution.wiz');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-web-solution-wiz',
template: templateSource || '',
    styles: [`

/* file: /opt/seasoncokr-v2/branch/main/build/src/app/portal.web.solution.wiz/view.scss */
.solution-intro {
  background-color: #2b2c2e !important;
  padding-top: 64px;
}
.solution-intro .info {
  display: flex;
  color: white;
  height: 140px;
  margin: auto;
  align-items: center;
}
@media (max-width: 768px) {
  .solution-intro .info {
    display: block;
    height: auto;
  }
}
.solution-intro .info .group {
  cursor: pointer;
  margin-right: 64px;
  margin-bottom: 24px;
}
.solution-intro .info .group .col-auto {
  display: flex;
  align-items: end;
}
.solution-intro .info .group h2 {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 64px;
  margin-bottom: 0px;
  padding: 0;
  cursor: pointer;
}
@media (max-width: 768px) {
  .solution-intro .info .group h2 {
    font-size: 48px;
  }
}
.solution-intro .info .group img {
  width: 40px;
  height: 40px;
}
.solution-intro .info h3 {
  font-family: SUIT;
  font-weight: bold;
  font-size: 20px;
  margin-top: 14px;
  margin-bottom: 14px;
}
.solution-intro .info span {
  color: #e9e8ee;
  font-family: SUIT;
  font-weight: normal;
  font-size: 16px;
}
.solution-intro .box {
  margin: auto;
}
.solution-intro .box img {
  position: relative;
  width: 100%;
  margin-top: 15px;
  border-radius: 20px;
}

.innovation {
  position: relative;
  display: flex;
  height: 100%;
  margin: auto;
  max-width: 1180px;
}
.innovation .col-md-3 {
  display: flex;
  padding: 16px;
}
.innovation .innovation-card {
  padding: 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  color: white;
}
.innovation .innovation-card h3 {
  text-align: center;
  font-weight: bold;
  font-family: SUIT;
  font-size: 20px;
}
.innovation .innovation-card .index {
  margin-bottom: 10px;
}
.innovation .innovation-card img {
  padding: 40px;
}
.innovation .innovation-card span {
  text-align: center;
  font-weight: normal;
  font-family: SUIT;
  font-size: 16px;
  line-height: 20px;
  white-space: break-spaces;
}
.innovation .innovation-card .text-gray {
  color: #9799A6;
}
.innovation .innovation-card .text-black {
  color: black;
}
.innovation .one {
  background-color: white;
}
.innovation .two {
  background-color: #9799A6;
}
.innovation .three {
  background-color: #494A52;
}
.innovation .four {
  background-color: #2E2F34;
}

.cases {
  display: flex;
  margin: auto;
  height: 90%;
  border-top: 1px solid #707070;
}
@media (max-width: 768px) {
  .cases {
    display: block;
  }
}
.cases .col-auto {
  display: flex;
  flex-direction: column;
  width: 240px;
  padding-top: 16px;
}
@media (max-width: 768px) {
  .cases .col-auto {
    width: 100%;
    display: block;
    white-space: nowrap;
    overflow: auto;
  }
  .cases .col-auto::-webkit-scrollbar {
    display: none;
  }
}
.cases .col-auto .cursor-pointer {
  display: block;
  margin-bottom: 12px;
  padding: 6px 0px;
  font-family: SUIT;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #494A52;
}
@media (max-width: 768px) {
  .cases .col-auto .cursor-pointer {
    display: inline-block;
    margin: 0 16px;
  }
}
.cases .col-auto .focus {
  color: #F75933;
  font-weight: bold;
}
.cases .col {
  display: flex;
  border-left: 1px solid #707070;
  padding: 45px;
  padding-right: 0;
  flex-direction: column;
}
@media (max-width: 768px) {
  .cases .col {
    padding: 0;
    border-left: none;
    margin-top: 24px;
  }
}
.cases .col .capture {
  display: flex;
  align-items: center;
}
.cases .col .capture img {
  width: 100%;
  border-radius: 0;
}
.cases .col .detail {
  margin: auto;
  margin-bottom: 32px;
}
.cases .col .detail h3 {
  font-family: SUIT;
  font-weight: bold;
  font-size: 24px;
  line-height: 1;
  color: #F75933;
  margin-bottom: 2px;
  padding: 0;
}
.cases .col .detail h2 {
  font-family: SUIT;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 16px;
  padding: 0;
  padding-left: 6px;
}
.cases .col .detail span {
  font: normal normal normal 16px/25px SUIT;
}`],
})
export class PortalWebSolutionWizComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.select(this.data[0]);
    }

    public selected: any = null;

    public data: any = [
        {
            index: "01",
            src: "/assets/page/solutions/wiz/webinar.png",
            ko: {
                title: "화상회의 서비스",
                detail: "WIZ는 웹 개발 뿐만 아니라 외부 시스템과의 스마트한 연동 능력으로 다각도의 솔루션 구축을 가능케 합니다. 대표적으로 화상회의 오픈소스 플랫폼인 BigBlueButton과의 유기적인 결합을 통해 혁신적인 화상회의 서비스를 현실화시켰습니다.",
            },
            en: {
                title: "Video Conferencing Service",
                detail: "WIZ enables the construction of multifaceted solutions through not only web development but also smart linking capabilities with external systems. For example, an innovative video conferencing service was realized through organic combination with BigBlueButton, an open source video conferencing platform.",
            }
        }, {
            index: "02",
            src: "/assets/page/solutions/wiz/kmbig.jpg",
            ko: {
                title: "데이터 공유/활용 시스템",
                detail: "WIZ를 사용하면 권한 부여, 데이터 공유, 검색 기능 등 복잡한 기능을 단순하게 구현할 수 있습니다. WIZ는 한의 빅데이터 통합 공유 시스템에 적용되어 다수의 사용자가 동시 접속하여 데이터를 검색하고 공유하는 것을 실증하였습니다.",
            },
            en: {
                title: "Data Sharing System",
                detail: "WIZ makes it simple to implement complex functionality such as authorization, data sharing, and search capabilities. WIZ was applied to Han's big data integrated sharing system and demonstrated that multiple users can access simultaneously to search and share data.",
            }
        }
    ];

    public info(item: any) {
        let lang: string = this.service.lang.get();
        if (item[lang]) return item[lang];
        return {};
    }

    public async select(item: any) {
        this.selected = item;
        await this.service.render();
    }

    public async github() {
        let url = "https://github.com/season-framework/wiz";
        window.open(url);
    }
}

export default PortalWebSolutionWizComponent;