import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.solutions');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-solutions',
template: templateSource || '',
    styles: [`

/* file: /opt/seasoncokr-v2/branch/main/build/src/app/page.solutions/view.scss */
.header {
  background-image: linear-gradient(108deg, rgba(0, 0, 0, 0.7), transparent), url("/assets/featured/solutions.jpg");
}`],
})
export class PageSolutionsComponent implements OnInit {
    public tab = "";

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.render();
        await this.service.trigger.call("scroll-top");
    }

    public async ngDoCheck() {
        if (WizRoute.segment.tab != this.tab) {
            this.tab = WizRoute.segment.tab;
            await this.service.trigger.call("scroll-top");
        }
    }
}

export default PageSolutionsComponent;