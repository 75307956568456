import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('layout.noscroll');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import $ from 'jquery';

@Component({
    selector: 'wiz-layout-noscroll',
template: templateSource || '',
    styles: [`

/* file: /opt/seasoncokr-v2/branch/main/build/src/app/layout.noscroll/view.scss */
body {
  width: 100%;
}

.wiz-page {
  width: 100%;
  margin: 0;
  z-index: 2;
  flex-direction: column;
}

.wiz-nav {
  max-width: 1448px;
  width: 90vw;
  margin: 0px auto;
}
@media (max-width: 768px) {
  .wiz-nav {
    width: 92vw;
  }
}

.wiz-view {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.wiz-view::-webkit-scrollbar {
  display: none;
}

.season-page {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  max-width: 1440px;
  width: 90vw;
  margin: 0px auto;
  border-top: 1px solid #2b2c2e;
  border-left: 1px solid #2b2c2e;
  border-right: 1px solid #2b2c2e;
}
@media (max-width: 768px) {
  .season-page {
    width: 92vw;
  }
}

.season-page::-webkit-scrollbar {
  display: none;
}`],
})
export class LayoutNoscrollComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        this.service.trigger.bind('scroll-top', async () => {
            $('.season-page-noscroll')[0].scroll({ top: 0, left: 0, behavior: 'smooth' });
        });
    }
}

export default LayoutNoscrollComponent;