import '@angular/compiler';
import { PortalSeasonLoadingDefaultComponent } from './portal.season.loading.default/portal.season.loading.default.component';
import { PortalWebSolutionWizComponent } from './portal.web.solution.wiz/portal.web.solution.wiz.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PageAdminComponent } from './page.admin/page.admin.component';
import { ComponentNavigationComponent } from './component.navigation/component.navigation.component';
import { PortalWebSolutionDizestComponent } from './portal.web.solution.dizest/portal.web.solution.dizest.component';
import { LayoutAdminComponent } from './layout.admin/layout.admin.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { PageAuthenticateComponent } from './page.authenticate/page.authenticate.component';
import { ComponentTypingComponent } from './component.typing/component.typing.component';
import { ComponentFooterComponent } from './component.footer/component.footer.component';
import { PageAdminNewsComponent } from './page.admin.news/page.admin.news.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PageContactComponent } from './page.contact/page.contact.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalWebSolutionWorksComponent } from './portal.web.solution.works/portal.web.solution.works.component';
import { PageNewsItemComponent } from './page.news.item/page.news.item.component';
import { PageSolutionsComponent } from './page.solutions/page.solutions.component';
import { ComponentNavAdminComponent } from './component.nav.admin/component.nav.admin.component';
import { PageMainComponent } from './page.main/page.main.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PageAdminCareerComponent } from './page.admin.career/page.admin.career.component';
import { AlertComponent } from './alert/alert.component';
import { PageAdminAboutPartnerComponent } from './page.admin.about.partner/page.admin.about.partner.component';
import { LayoutSeasonComponent } from './layout.season/layout.season.component';
import { PageAdminAboutTeamComponent } from './page.admin.about.team/page.admin.about.team.component';
import { PageAdminAboutHistoryComponent } from './page.admin.about.history/page.admin.about.history.component';
import { PageAdminNewsItemComponent } from './page.admin.news.item/page.admin.news.item.component';
import { PageNewsComponent } from './page.news/page.news.component';
import { PageCareerComponent } from './page.career/page.career.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { LayoutNoscrollComponent } from './layout.noscroll/layout.noscroll.component';
import { PageAboutComponent } from './page.about/page.about.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SortablejsModule } from "src/libs/portal/season/ngx-sortablejs";

@NgModule({
    declarations: [
        AppComponent,
        PortalSeasonLoadingDefaultComponent,
        PortalWebSolutionWizComponent,
        PortalSeasonAlertComponent,
        PortalSeasonLoadingHexaComponent,
        PageAdminComponent,
        ComponentNavigationComponent,
        PortalWebSolutionDizestComponent,
        LayoutAdminComponent,
        PortalSeasonTabComponent,
        PortalSeasonPagenationComponent,
        PageAuthenticateComponent,
        ComponentTypingComponent,
        ComponentFooterComponent,
        PageAdminNewsComponent,
        PortalSeasonStatusbarComponent,
        PageContactComponent,
        LayoutEmptyComponent,
        PortalWebSolutionWorksComponent,
        PageNewsItemComponent,
        PageSolutionsComponent,
        ComponentNavAdminComponent,
        PageMainComponent,
        PortalSeasonLoadingSeasonComponent,
        PageAdminCareerComponent,
        AlertComponent,
        PageAdminAboutPartnerComponent,
        LayoutSeasonComponent,
        PageAdminAboutTeamComponent,
        PageAdminAboutHistoryComponent,
        PageAdminNewsItemComponent,
        PageNewsComponent,
        PageCareerComponent,
        PortalSeasonViewerTreeComponent,
        LayoutNoscrollComponent,
        PageAboutComponent,
        PortalSeasonUiDropdownComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        SortablejsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
            defaultLanguage: 'ko'
        })
        
    ],
    providers: [{
        provide: COMPOSITION_BUFFER_MODE,
        useValue: false
    }],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/lang/', '.json');
}