import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.web.solution.works');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-web-solution-works',
template: templateSource || '',
    styles: [`

/* file: /opt/seasoncokr-v2/branch/main/build/src/app/portal.web.solution.works/view.scss */
.solution-intro {
  background-color: #2b2c2e !important;
  padding-top: 64px;
}
.solution-intro .info {
  display: flex;
  color: white;
  height: 140px;
  margin: auto;
  align-items: center;
}
@media (max-width: 768px) {
  .solution-intro .info {
    display: block;
    height: auto;
  }
}
.solution-intro .info .group {
  cursor: pointer;
  margin-right: 64px;
  margin-bottom: 24px;
}
.solution-intro .info .group .col-auto {
  display: flex;
  align-items: end;
}
.solution-intro .info .group h2 {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 64px;
  margin-bottom: 0px;
  padding: 0;
  cursor: pointer;
}
@media (max-width: 768px) {
  .solution-intro .info .group h2 {
    font-size: 48px;
  }
}
.solution-intro .info .group img {
  width: 40px;
  height: 40px;
}
.solution-intro .info h3 {
  font-family: SUIT;
  font-weight: bold;
  font-size: 20px;
  margin-top: 14px;
  margin-bottom: 14px;
}
.solution-intro .info span {
  color: #e9e8ee;
  font-family: SUIT;
  font-weight: normal;
  font-size: 16px;
}
.solution-intro .box {
  margin: auto;
}
.solution-intro .box img {
  position: relative;
  width: 100%;
  margin-top: 15px;
  border-radius: 20px;
}

.innovation {
  position: relative;
  display: flex;
  height: 100%;
  margin: auto;
  max-width: 1180px;
}
.innovation .col-md-3 {
  display: flex;
  padding: 16px;
}
.innovation .innovation-card {
  padding: 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  color: white;
}
.innovation .innovation-card h3 {
  text-align: center;
  font-weight: bold;
  font-family: SUIT;
  font-size: 20px;
}
.innovation .innovation-card .index {
  margin-bottom: 10px;
}
.innovation .innovation-card img {
  padding: 40px;
}
.innovation .innovation-card span {
  text-align: center;
  font-weight: normal;
  font-family: SUIT;
  font-size: 16px;
  line-height: 20px;
  white-space: break-spaces;
}
.innovation .innovation-card .text-gray {
  color: #9799A6;
}
.innovation .innovation-card .text-black {
  color: black;
}
.innovation .one {
  background-color: white;
}
.innovation .two {
  background-color: #9799A6;
}
.innovation .three {
  background-color: #494A52;
}
.innovation .four {
  background-color: #2E2F34;
}

.cases {
  display: flex;
  margin: auto;
  height: 90%;
  border-top: 1px solid #707070;
}
.cases .col-auto {
  display: flex;
  flex-direction: column;
  width: 240px;
  padding-top: 16px;
}
.cases .col-auto .cursor-pointer {
  display: block;
  margin-bottom: 12px;
  padding: 6px 0px;
  font-family: SUIT;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #494A52;
}
.cases .col-auto .focus {
  color: #F75933;
  font-weight: bold;
}
.cases .col {
  display: flex;
  border-left: 1px solid #707070;
  padding: 45px;
  padding-right: 0;
  flex-direction: column;
}
.cases .col .capture {
  display: flex;
  align-items: center;
}
.cases .col .capture img {
  width: 100%;
  border-radius: 0;
}
.cases .col .detail {
  margin: auto;
  margin-bottom: 32px;
}
.cases .col .detail h3 {
  font-family: SUIT;
  font-weight: bold;
  font-size: 24px;
  line-height: 1;
  color: #F75933;
  margin-bottom: 2px;
  padding: 0;
}
.cases .col .detail h2 {
  font-family: SUIT;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 16px;
  padding: 0;
  padding-left: 6px;
}
.cases .col .detail span {
  font: normal normal normal 16px/25px SUIT;
}`],
})
export class PortalWebSolutionWorksComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }

    public async github() {
        let url = "https://works.season.co.kr";
        window.open(url);
    }
}

export default PortalWebSolutionWorksComponent;