import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.typing');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-typing',
template: templateSource || '',
    styles: [`

/* file: /opt/seasoncokr-v2/branch/main/build/src/app/component.typing/view.scss */
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.typing-text-container {
  font-family: main-eb;
  font-size: 36px;
  color: #fff;
  margin-top: 12px;
  margin-bottom: 12px;
  position: relative;
  line-height: 1;
  text-align: center;
  display: inline-block;
}
.typing-text-container .text_cursor {
  position: absolute;
  height: 36px;
  top: 0;
  right: -8px;
  background-color: transparent;
  border-left: 3px solid #fff;
  animation: blink 0.8s infinite;
}`],
})
export class ComponentTypingComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() text: any;
    @Input() style: any = {};
    @Input() cursorStyle: any = {};
    @Input() timelaps: number = 100;

    public _text: any = [];
    public current = { i: 0, j: 0 };

    public async ngOnInit() {
        await this.service.init();
        this._text = this.text.split("\n");
        for (let i = 0; i < this._text.length; i++)
            this._text[i] = this._text[i].split("");

        await this.service.render(this.timelaps);

        for (let i = 0; i < this.text.length; i++) {
            if (!this._text[i]) continue;
            this.current.i = i;
            for (let j = 0; j < this._text[i].length; j++) {
                this.current.j = j + 1;
                await this.service.render(this.timelaps);
            }
            await this.service.render(this.timelaps);
        }
    }

    public check(i: number, j: number) {
        if (i < this.current.i) return true;
        if (i == this.current.i && j < this.current.j) return true;
        return false;
    }
}

export default ComponentTypingComponent;