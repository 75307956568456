import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.navigation');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Component } from '@angular/core';
import { HostListener } from '@angular/core';

@Component({
    selector: 'wiz-component-navigation',
template: templateSource || '',
    styles: [`

/* file: /opt/seasoncokr-v2/branch/main/build/src/app/component.navigation/view.scss */
.season-nav {
  height: 70px;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 20px;
  z-index: 11;
}
@media (max-width: 768px) {
  .season-nav {
    margin-top: 8px;
    margin-bottom: 12px;
  }
}
.season-nav .menu.highlight {
  background-color: #F75933;
  color: white;
}
.season-nav .logo {
  display: flex;
  align-items: center;
  height: 70px;
  border-radius: 15px;
  padding: 20px 50px;
  background-color: white;
  cursor: pointer;
}
.season-nav .menu {
  display: flex;
  align-items: center;
  height: 70px;
  width: 164px;
  padding: 0 24px;
  background-color: white;
  cursor: pointer;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  justify-content: center;
  margin: 0px 10px;
  position: relative;
}
.season-nav .menu:hover {
  background-color: #F75933;
  color: white;
}
.season-nav .menu span {
  margin-top: 10px;
}
.season-nav .menu h3 {
  font-size: 15px;
  font: normal normal bold 18px/22px Montserrat;
}
.season-nav .menubar {
  width: 80px;
  margin-right: 0 !important;
}
.season-nav .menu-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 15px;
}
.season-nav .box {
  display: flex;
  position: relative;
  margin: 0px 10px;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 130px;
  border-radius: 15px;
  background-color: white;
  cursor: pointer;
}
.season-nav .box span {
  margin-top: 10px;
}
.season-nav .box h3 {
  font: normal normal bold 18px/22px Montserrat;
  margin: 0;
  padding: 24px 17px;
}
.season-nav .box button {
  padding: 0px;
  width: 170px;
  border: none;
  border-radius: 15px;
}
.season-nav .box button:active {
  color: white;
}
.season-nav .on {
  background-color: #F75933;
  color: white;
  cursor: pointer;
  border-radius: 15px;
}
.season-nav .dropdown {
  width: 170px;
  z-index: 1000;
  padding: 0;
}
.season-nav .dropdown .dropdown-box {
  display: none;
  position: absolute;
  left: -40px;
  top: 45px;
}
.season-nav .dropdown ul li {
  margin-top: 10px;
}
.season-nav .dropdown li {
  position: relative;
  text-align: center;
  list-style: none;
}
.season-nav .dropdown li:hover .dropdown-box {
  display: block;
}
.season-nav .dropdown:hover .dropdown-box {
  display: block;
  padding: 0;
}
.season-nav .dropdown a {
  display: block;
  height: 70px;
  width: 170px;
  padding: 24px;
  border-radius: 15px;
  color: black;
  background: white;
  text-decoration: none;
}
.season-nav .dropdown a.active,
.season-nav .dropdown a:hover {
  background: #FEF2ED;
  color: #F75933;
  border: 1px #F75933 solid;
  transition: 0.2s;
}

.nav-toggler {
  display: none;
}

.nav-collapse {
  display: block;
}

@media (max-width: 768px) {
  .nav-toggler {
    display: block;
  }
  .nav-collapse {
    display: none;
  }
}
.nav-menus {
  display: none;
  color: #fff;
}
@media (max-width: 768px) {
  .nav-menus {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    overflow: auto;
  }
  .nav-menus.closed {
    display: none;
  }
}
.nav-menus .close-btn {
  width: 100%;
  padding-top: 24px;
  padding-right: 36px;
  text-align: right;
  font-size: 36px;
}
.nav-menus .menu-item-container {
  padding: 24px 48px;
}
.nav-menus .menu-item-container .menu-item {
  color: #fff;
  padding: 24px 12px;
  padding-top: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid #fff;
  font-size: 18px;
}
.nav-menus .lang {
  padding: 24px 48px;
  font-size: 18px;
}
.nav-menus .lang .col {
  text-align: center;
}`],
})
export class ComponentNavigationComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }

    @HostListener('document:click')
    public clickout() {
        this.service.navbar.toggle(true);
    }

    public async switchLanguage(lang: string) {
        await this.service.lang.set(lang);
    }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0
    }
}

export default ComponentNavigationComponent;