import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.career');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-career',
template: templateSource || '',
    styles: [`

/* file: /opt/seasoncokr-v2/branch/main/build/src/app/page.career/view.scss */
.header {
  background-image: linear-gradient(108deg, rgba(0, 0, 0, 0.5), transparent), url("/assets/featured/career.jpg");
}

.content-talent .col-md-4 {
  padding: 12px;
}
.content-talent .talent {
  width: 100%;
  padding: 30px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 418px;
  margin-bottom: 12px;
}
.content-talent .talent .text {
  width: 100%;
  text-align: center;
}
.content-talent .talent .text .index {
  font-weight: bold;
  font-family: SUIT;
  font-size: 24px;
  color: #9799A6;
  margin: 0;
  padding: 0;
  margin-bottom: 6px;
}
.content-talent .talent .text .title {
  font-weight: bold;
  font-family: SUIT;
  font-size: 24px;
  padding: 0;
  margin: 0 auto;
  margin-bottom: 14px;
}
.content-talent .talent .text .desc {
  font-weight: normal;
  font-family: SUIT;
  font-size: 16px;
  line-height: 18px;
  color: #494A52;
  margin-bottom: 24px;
}
.content-talent .talent .img {
  padding: 36px;
  background-color: #E9E8EE;
  border-radius: 20px;
}
.content-talent .talent .img img {
  height: 110px;
}

.content-benefit .col-md-3 {
  padding: 16px;
  padding-bottom: 24px;
}
.content-benefit h4 {
  margin-top: 30px;
  margin-bottom: 10px;
  font-family: SUIT;
  font-weight: bold;
  font-size: 20px;
}
.content-benefit span {
  color: #494A52;
  font-family: SUIT;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}
.content-benefit img {
  padding: 2px;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .content-recruit .d-flex {
    display: block !important;
  }
}
.content-recruit .step {
  width: 20%;
  margin: 30px auto 0;
}
@media (max-width: 768px) {
  .content-recruit .step {
    display: block;
    width: 100%;
  }
}
.content-recruit .step .circle {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content-recruit .step .circle h3 {
  font-family: SUIT;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 8px;
}
.content-recruit .step .circle span {
  font-family: SUIT;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  width: 80%;
  display: block;
}
@media (max-width: 768px) {
  .content-recruit .step .circle {
    display: block;
    width: 100%;
    border-radius: 0;
    aspect-ratio: auto;
    padding: 8px 12px;
  }
  .content-recruit .step .circle span {
    width: 100%;
  }
}
.content-recruit .step .light-gray {
  background-color: #9799A6;
}
.content-recruit .step .orange {
  background-color: #F75933;
  color: white;
}
.content-recruit .step .dark-gray {
  background-color: #494a52;
  color: white;
}
.content-recruit .step .detail {
  margin-top: 50px;
  font-family: SUIT;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}
.content-recruit .next {
  display: flex;
  align-items: center;
}
.content-recruit .next i {
  font-size: 34px;
  margin-bottom: 100px;
}
@media (max-width: 768px) {
  .content-recruit .next {
    display: none;
  }
}

.post {
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  padding: 30px;
  margin: 15px auto;
  max-width: 1180px;
  background-color: white;
  cursor: pointer;
}
.post button {
  margin: 10px 0px;
  margin-left: 15px;
  border-radius: 15px;
  border: none;
  height: 30px;
  font: normal normal normal 15px/30px Montserrat;
}
.post .always {
  background-color: #FDDED6;
}
.post .focus {
  background-color: #FA9B85;
}
.post h3 {
  font: normal normal bold 28px/50px Montserrat;
}
.post span {
  font: normal normal normal 16px/30px Montserrat;
}

.post:hover {
  border: 1px solid #F75933;
  background-color: #FFF2ED;
}

.btn-post {
  border: 1px solid black;
  padding: 12px 30px;
  border-radius: 30px;
  font: normal normal normal 16px/25px SUIT;
  margin: 0px 5px;
}
.btn-post span {
  margin-right: 10px;
}

.opt {
  color: #72787F;
  border: 1px solid #72787F;
}
.opt:hover {
  color: #FF5933;
  border: 1px solid #FF5933;
}

.btn-post.on {
  color: #FF5933;
  border: 1px solid #FF5933;
  background-color: #FFF2ED;
}

.blank {
  display: flex;
  padding: 150px;
  font: normal normal normal 20px/25px SUIT;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .blank {
    padding: 0;
  }
}`],
})
export class PageCareerComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public jobs: any = [];

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        await this.service.trigger.call("scroll-top");
    }

    public async load() {
        const { data } = await wiz.call("load");
        this.jobs = data;
        await this.service.render();
    }

    public async href(url) {
        window.open(url);
    }
}

export default PageCareerComponent;