import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.about.partner');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-admin-about-partner',
template: templateSource || '',
    styles: [`

/* file: /opt/seasoncokr-v2/branch/main/build/src/app/page.admin.about.partner/view.scss */
.content {
  margin-top: 0;
  border-radius: 0;
  padding-top: 32px;
}

.btn-addnew {
  border: 1px dashed #FE5A33;
  color: #FE5A33;
}
.btn-addnew:hover {
  background-color: #FE5A33;
  color: #fff;
  border: 1px solid #FE5A33;
}

.workspace-list-item .container {
  min-height: 100%;
  position: relative;
}

.content-partner {
  background-color: #2b2c2e;
  color: #fff;
  min-height: 100%;
}
.content-partner .partner-card {
  margin: 12px 6px;
  height: 120px;
  background-color: #F5F5F5;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.content-partner .partner-card img {
  width: 60%;
  z-index: 9;
}
.content-partner .partner-card .remover {
  display: none;
  width: 100%;
  height: 100%;
}
.content-partner .partner-card:hover {
  border: 1px solid #FE5A33;
}
.content-partner .partner-card:hover .remover {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: #FE5A33;
  z-index: 100;
}
.content-partner .partner-card-new {
  border-radius: 20px;
  border: 1px dashed #FE5A33;
  color: #FE5A33;
  cursor: pointer;
  font-size: 32px;
}
.content-partner .partner-card-new:hover {
  border: 1px solid #FE5A33;
  border-color: #FE5A33;
  color: #fff;
  background-color: #FE5A33;
}`],
})
export class PageAdminAboutPartnerComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public data: any = {};

    public config: any = {
        partner: {
            animation: 0,
            filter: '.filtered'
        }
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/authenticate');
        await this.load();
    }

    public async load() {
        let res = await wiz.call("partner");
        this.data = res.data;
        await this.service.render();
    }

    public partner() {
        try {
            let data: any = this.data;
            let res: any = data.ko.data;
            return res;
        } catch (e) {
        }
        return [];
    }

    public async addPartner() {
        let img = await this.service.file.read({ type: 'image', accept: 'image/*', width: 256, quality: 1 });
        let target: any = this.partner();
        target.push(img);
        await this.service.render();
    }

    public async removePartner(target: any) {
        let res: any = await this.alert("정말로 삭제하시겠습니까?", "", "error", "삭제", "취소");
        if (!res) return;
        let parent: any = this.partner();
        parent.remove(target);
        await this.service.render();
    }

    public async update() {
        let data: any = this.data;
        await wiz.call("update", { data: JSON.stringify(data) });
        await this.load();
        await this.alert('저장되었습니다', 'Saved', 'success', '확인');
    }

    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

}

export default PageAdminAboutPartnerComponent;