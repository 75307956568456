import '@angular/compiler';
import { PortalSeasonLoadingDefaultComponent } from './portal.season.loading.default/portal.season.loading.default.component';
import { PortalWebSolutionWizComponent } from './portal.web.solution.wiz/portal.web.solution.wiz.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PageAdminComponent } from './page.admin/page.admin.component';
import { ComponentNavigationComponent } from './component.navigation/component.navigation.component';
import { PortalWebSolutionDizestComponent } from './portal.web.solution.dizest/portal.web.solution.dizest.component';
import { LayoutAdminComponent } from './layout.admin/layout.admin.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { PageAuthenticateComponent } from './page.authenticate/page.authenticate.component';
import { ComponentTypingComponent } from './component.typing/component.typing.component';
import { ComponentFooterComponent } from './component.footer/component.footer.component';
import { PageAdminNewsComponent } from './page.admin.news/page.admin.news.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PageContactComponent } from './page.contact/page.contact.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalWebSolutionWorksComponent } from './portal.web.solution.works/portal.web.solution.works.component';
import { PageNewsItemComponent } from './page.news.item/page.news.item.component';
import { PageSolutionsComponent } from './page.solutions/page.solutions.component';
import { ComponentNavAdminComponent } from './component.nav.admin/component.nav.admin.component';
import { PageMainComponent } from './page.main/page.main.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PageAdminCareerComponent } from './page.admin.career/page.admin.career.component';
import { AlertComponent } from './alert/alert.component';
import { PageAdminAboutPartnerComponent } from './page.admin.about.partner/page.admin.about.partner.component';
import { LayoutSeasonComponent } from './layout.season/layout.season.component';
import { PageAdminAboutTeamComponent } from './page.admin.about.team/page.admin.about.team.component';
import { PageAdminAboutHistoryComponent } from './page.admin.about.history/page.admin.about.history.component';
import { PageAdminNewsItemComponent } from './page.admin.news.item/page.admin.news.item.component';
import { PageNewsComponent } from './page.news/page.news.component';
import { PageCareerComponent } from './page.career/page.career.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { LayoutNoscrollComponent } from './layout.noscroll/layout.noscroll.component';
import { PageAboutComponent } from './page.about/page.about.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';

const INDEX_PAGE = "main";

import { URLPattern } from "urlpattern-polyfill";
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

let app_routes: Routes = [
    {
        component: LayoutAdminComponent,
        "children": [
            {
                "path": "admin",
                component: PageAdminComponent,
                "app_id": "page.admin"
            },
            {
                "path": "admin/news/:category",
                component: PageAdminNewsComponent,
                "app_id": "page.admin.news"
            },
            {
                "path": "admin/career",
                component: PageAdminCareerComponent,
                "app_id": "page.admin.career"
            },
            {
                "path": "admin/about/partner",
                component: PageAdminAboutPartnerComponent,
                "app_id": "page.admin.about.partner"
            },
            {
                "path": "admin/about/team",
                component: PageAdminAboutTeamComponent,
                "app_id": "page.admin.about.team"
            },
            {
                "path": "admin/about/history",
                component: PageAdminAboutHistoryComponent,
                "app_id": "page.admin.about.history"
            },
            {
                "path": "admin/news/:category/:id",
                component: PageAdminNewsItemComponent,
                "app_id": "page.admin.news.item"
            }
        ]
    },
    {
        component: LayoutEmptyComponent,
        "children": [
            {
                "path": "authenticate",
                component: PageAuthenticateComponent,
                "app_id": "page.authenticate"
            }
        ]
    },
    {
        component: LayoutNoscrollComponent,
        "children": [
            {
                "path": "contact",
                component: PageContactComponent,
                "app_id": "page.contact"
            },
            {
                "path": "news/:category/:id",
                component: PageNewsItemComponent,
                "app_id": "page.news.item"
            },
            {
                "path": "news",
                component: PageNewsComponent,
                "app_id": "page.news"
            }
        ]
    },
    {
        component: LayoutSeasonComponent,
        "children": [
            {
                "path": "solutions/:tab?",
                component: PageSolutionsComponent,
                "app_id": "page.solutions"
            },
            {
                "path": "main",
                component: PageMainComponent,
                "app_id": "page.main"
            },
            {
                "path": "career",
                component: PageCareerComponent,
                "app_id": "page.career"
            },
            {
                "path": "about",
                component: PageAboutComponent,
                "app_id": "page.about"
            }
        ]
    }
];

export class RouteInfo {
    public path: any = "";
    public segment: any = {};

    constructor() { }
}

window.WizRoute = new RouteInfo();

let patternMatcher = (pattern: any, url: any) => {
    let urlpath = url.map((x: any) => x.path).join("/");
    let testurl = 'http://test/';
    pattern = '/' + pattern;
    urlpath = testurl + urlpath;
    pattern = new URLPattern({ pathname: pattern });
    pattern = pattern.exec(urlpath)
    if (pattern && pattern.pathname) {
        let posParams = {};
        for (let key in pattern.pathname.groups) {
            if (pattern.pathname.groups[key]) {
                posParams[key] = pattern.pathname.groups[key];
            }
        }
        window.WizRoute.path = url.map((x: any) => x.path).join("/");
        window.WizRoute.segment = posParams;

        return { consumed: url, posParams: posParams };
    }
    return null
}

let routes: Routes = [{
    matcher: (url: any) => {
        for (let i = 0; i < app_routes.length; i++) {
            let layout = app_routes[i];
            let layout_childs = layout.children;
            for (let j = 0; j < layout_childs.length; j++) {
                let child = layout_childs[j];
                let matcher = patternMatcher(child.path, url);
                if (matcher)
                    return null;
            }
        }
        return { consumed: url, posParams: {} };
    },
    redirectTo: INDEX_PAGE
}];

for (let i = 0; i < app_routes.length; i++) {
    let layout = app_routes[i];
    let layout_component = layout.component;
    let layout_childs = layout.children;

    let router: any = {
        path: '',
        component: layout_component,
        children: []
    };

    for (let j = 0; j < layout_childs.length; j++) {
        let child = layout_childs[j];
        router.children.push({
            matcher: (url: any) => {
                let matcher = patternMatcher(child.path, url);
                if (matcher) return matcher;
                return null;
            },
            component: child.component
        });
    }
    routes.push(router);
}

@NgModule({ imports: [RouterModule.forRoot(routes)], exports: [RouterModule] })
export class AppRoutingModule { }