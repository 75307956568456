import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.about.history');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-admin-about-history',
template: templateSource || '',
    styles: [`

/* file: /opt/seasoncokr-v2/branch/main/build/src/app/page.admin.about.history/view.scss */
.content {
  margin-top: 0;
  border-radius: 0;
  padding-top: 32px;
}

.content-history input {
  text-align: left;
}
.content-history .history {
  max-width: 1180px;
  margin: auto;
}
.content-history .history .row {
  margin-bottom: 48px;
}
.content-history .history .year input {
  width: 180px;
  font-weight: bold;
  font-family: Montserrat;
  margin: 0;
  padding: 0;
  font-size: 48px;
}
.content-history .history .month input {
  width: 160px;
  color: #9799A6;
  font-size: 24px;
  font-weight: bold;
  font-family: Montserrat;
  margin: 0;
  padding: 0;
}
.content-history .history .title input {
  color: #2B2C2E;
  font-weight: normal;
  font-size: 20px;
  font-family: Montserrat;
}
.content-history .history .list {
  display: flex;
  align-items: center;
  padding: 8px 0px;
  border-bottom: 1px solid #9799A6;
}

.btn-plus {
  border: 1px dashed #FE5A33;
  color: #FE5A33;
}
.btn-plus:hover {
  background-color: #FE5A33;
  color: #fff;
  border: 1px solid #FE5A33;
}`],
})
export class PageAdminAboutHistoryComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public data: any = {};

    public config: any = {
        year: {
            animation: 0
        },
        item: {
            animation: 0,
            group: 'list'
        }
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/authenticate');
        await this.load();
    }

    public async load() {
        let res = await wiz.call("history");
        this.data = res.data;
        await this.service.render();
    }

    public history() {
        try {
            let lang: any = this.service.lang.get();
            let data: any = this.data;
            let res: any = data.ko.data;
            if (data[lang]) res = data[lang].data;
            return res;
        } catch (e) {
        }
        return [];
    }

    public async addItem(target: any) {
        target.items.push({ month: '', title: '' });
        await this.service.render();
    }

    public async removeItem(parent: any, target: any) {
        parent.items.remove(target);
        await this.service.render();
    }

    public async addYear() {
        let target: any = this.history();
        target.unshift({ year: '', items: [] });
        await this.service.render();
    }

    public async removeYear(target: any) {
        let parent: any = this.history();
        parent.remove(target);
        await this.service.render();
    }

    public async update() {
        let data: any = this.data;
        await wiz.call("update", { data: JSON.stringify(data) });
        await this.load();
        await this.alert('저장되었습니다', 'Saved', 'success', '확인');
    }

    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

}

export default PageAdminAboutHistoryComponent;