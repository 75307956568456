import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.about.team');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-admin-about-team',
template: templateSource || '',
    styles: [`

/* file: /opt/seasoncokr-v2/branch/main/build/src/app/page.admin.about.team/view.scss */
.content {
  margin-top: 0;
  border-radius: 0;
  padding-top: 32px;
}

.team {
  max-width: 1180px;
  margin: auto;
  margin-bottom: 64px;
}
.team input {
  border-radius: 0;
}
.team input {
  text-align: left;
}
.team .title {
  display: flex;
  margin-bottom: 8px;
}
.team .title input {
  font: normal normal bold 24px/30px SUIT;
}
.team .detail {
  color: #72787F;
}
.team .detail input {
  font: normal normal normal 16px/20px SUIT;
}
.team .row {
  margin-top: 24px;
  border-top: 1px solid #C9CDD2;
}
.team .row .profile {
  margin-top: 48px;
  margin-left: 0px;
  width: 212px;
  margin: 48px 30px 0px 0px;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.team .row .profile .image {
  width: 212px;
  height: 212px;
  border-radius: 10px;
  border: 1px solid #000;
  cursor: pointer;
}
.team .row .profile .image:hover {
  border-color: #FE5A33;
}
.team .row .profile .name {
  margin-top: 24px;
  margin-left: 0px;
  display: flex;
}
.team .row .profile .name input {
  font: normal normal bold 24px/30px SUIT;
}
.team .row .profile .position {
  margin-left: 0px;
}
.team .row .profile .position input {
  font: normal normal normal 16px/20px SUIT;
}
.team .row .profile .email {
  margin-left: 0px;
}
.team .row .profile .email input {
  font: normal normal normal 16px/20px SUIT;
}
.team .row .profile-new {
  border-radius: 12px;
  border: 1px dashed #FE5A33;
  color: #FE5A33;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-height: 64px;
  font-size: 48px;
}
.team .row .profile-new:hover {
  border: 1px solid #FE5A33;
  border-color: #FE5A33;
  color: #fff;
  background-color: #FE5A33;
}
.team .row .btn {
  padding: 0 8px;
  font-size: 12px;
}

.btn-addnew {
  border: 1px dashed #FE5A33;
  color: #FE5A33;
}
.btn-addnew:hover {
  background-color: #FE5A33;
  color: #fff;
  border: 1px solid #FE5A33;
}`],
})
export class PageAdminAboutTeamComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public data: any = {};

    public config: any = {
        team: {
            animation: 0
        },
        member: {
            animation: 0,
            handle: '.profile-handle',
            group: 'list'
        }
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/authenticate');
        await this.load();
    }

    public async load() {
        let res = await wiz.call("team");
        this.data = res.data;
        await this.service.render();
    }

    public team() {
        try {
            let lang: any = this.service.lang.get();
            let data: any = this.data;
            let res: any = data.ko.data;
            if (data[lang]) res = data[lang].data;
            return res;
        } catch (e) {
        }
        return [];
    }

    public async addMember(target: any) {
        target.member.push({});
        await this.service.render();
    }

    public async removeMember(parent: any, target: any) {
        let res: any = await this.alert("정말로 삭제하시겠습니까?", "", "error", "삭제", "취소");
        if (!res) return;
        parent.member.remove(target);
        await this.service.render();
    }

    public async addTeam() {
        let target: any = this.team();
        target.push({ member: [] });
        await this.service.render();
    }

    public async removeTeam(target: any) {
        let res: any = await this.alert("정말로 삭제하시겠습니까?", "", "error", "삭제", "취소");
        if (!res) return;
        let parent: any = this.team();
        parent.remove(target);
        await this.service.render();
    }

    public async update() {
        let data: any = this.data;
        await wiz.call("update", { data: JSON.stringify(data) });
        await this.load();
        await this.alert('저장되었습니다', 'Saved', 'success', '확인');
    }

    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async updateIcon(item: any) {
        let icon = await this.service.file.read({ type: 'image', accept: 'image/*', width: 256, quality: 1 });
        item.img = icon;
        await this.service.render();
    }

}

export default PageAdminAboutTeamComponent;